import React from 'react'
import { Card, Text, IconButton } from 'theme-ui'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import Section from '@components/Section'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import { Email } from "react-obfuscate-email";

const ContactInfo = () => {
  const { phone, address, email } = useSiteMetadata()

  return (
    <Section aside title='Collaborate'>
      <Card variant='paper'>
        <Text variant='p'>Interested in working together?</Text>
        {/* <Text variant='p'>Let's talk about your project.</Text> */}
        {/* {phone && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaPhone />
            </IconButton>
            {phone}
          </Text>
        )} */}
        {email && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaEnvelope size={10}/>
            </IconButton>
            {/* {email} */}
            <Email email={email}>Email us!</Email>
          </Text>
        )}
        {address && (
          <Text>
            {/* <IconButton variant='simple' role='presentation'>
              <FaLocationArrow />
            </IconButton> */}
            {address}
          </Text>
        )}
      </Card>
    </Section>
  )
}

export default ContactInfo
