import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='STAR Vision' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        STAR - Speech & Text Applied Research, is a research lab for artificial
        intelligence at Mohamed bin Zayed University of Artificial Intelligence
        (MBZUAI). STAR is committed to progress in AI research, and is a place
        where like-minded people can come to work together in the pursuit of
        knowledge, understanding and eventually solutions to the many challenges
        of AI development.
      </Text>
    </Card>
  </Section>
)

export default Commitment
